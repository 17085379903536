import helpers from '@/helpers'

export const mutationsFunctions = {
    setInit: (state, payload) => {
        state.idUsuarioLogado = payload.idUsuarioLogado;
        state.descricao = null;
        state.doenca = null;
        state.qtd_adesoes = 0;
        state.grupos_com_adesoes = [];
        state.vacinas = [];
        state.data_inicio = null;
        state.data_termino = null;
        state.grupos = {};
        state.updatedAt = helpers.geraNumeroAleatorio();
        state.precificacao = {}
        state.distribuicao = {}
        state.formulario = {}
        state.response = {
            status: null,
            message: [],
        };
    },
    setUpdatedAt: (state, watchers = []) => {
        state.updatedAt = helpers.geraNumeroAleatorio()
        if (watchers.length > 0) {
            watchers.forEach((key) => {
                state.watchers[key] = helpers.geraNumeroAleatorio()
            })
        }
    },
    setFormDesabilitarEnvio: (state, ativo) => {
        state.form.desabilitarEnvio = ativo
    },
    setFormDoencas: (state, doencas) => {
        state.form.doencas = doencas
    },
    setDataInicioSelecionado: (state, dataInicio) => {
        state.data_inicio = dataInicio
    },
    setDataTerminoSelecionado: (state, dataTermino) => {
        state.data_termino = dataTermino
    },
    setFormVacinas: (state, vacinas) => {
        state.form.vacinas = vacinas
    },
    setFormGrupos: (state, grupos) => {
        let response = grupos.data != undefined ? grupos.data : grupos
        state.form.grupos = response.filter((grupo) => grupo.operador.length > 0)
    },
    setDescricao: (state, descricao) => {
        state.descricao = descricao
    },
    setDoencaSelecionada: (state, doenca) => {
        state.doenca = doenca
    },
    setQtdAdesoes: (state, qtd) => {
        state.qtd_adesoes = qtd
    },
    setVacinasSelecionada: (state, vacinas) => {
        if (vacinas.length) {
            let vacinasAntigo = state.vacinas.map(vacina => vacina.id_vacina)
            let vacinasNova = vacinas.map(vacina => vacina.id_vacina)
            let vacinaRemovida = vacinasNova.filter(idVacina => !vacinasAntigo.includes(idVacina));

            state.vacinas = vacinas

            vacinas.forEach((vacina) => {
                if (!state.precificacao[vacina.id_vacina]) {
                    state.precificacao[vacina.id_vacina] = {};
                    state.distribuicao[vacina.id_vacina] = {};
                    state.grupos[vacina.id_vacina] = {};
                }

                state.documentos[vacina.id_vacina] = {};
            });

            if (vacinaRemovida.length) {
                vacinaRemovida.forEach((idVacina) => {
                    state.precificacao[idVacina] = {};
                    state.distribuicao[idVacina] = {};
                    state.grupos[idVacina] = {};
                    state.documentos[idVacina] = {};
                })
            }
        }
    },
    resetVacinas: (state) => {
        state.vacinas = [];
    },
    deleteGrupoSelecionado: (state, { vacina, grupo }) => {
        delete state.precificacao[vacina.id_vacina][grupo.id_grupo]
        delete state.distribuicao[vacina.id_vacina][grupo.id_grupo]
        delete state.documentos[vacina.id_vacina][grupo.id_grupo]
        delete state.grupos[vacina.id_vacina][grupo.id_grupo]
    },
    setGruposSelecionadoPorVacina: (state, { vacina, grupos }) => {
        grupos.forEach((grupo) => {
            state.grupos[vacina.id_vacina][grupo.id_grupo] = grupo
            state.documentos[vacina.id_vacina][grupo.id_grupo] = [];

            const precificacao = state.precificacao[vacina.id_vacina][grupo.id_grupo],
                distribuicao = state.distribuicao[vacina.id_vacina][grupo.id_grupo];

            let operadorPrecificacao = {},
                operadorDistribuicao = {};

            if (grupo.operador) {
                grupo.operador.forEach((op) => {
                    operadorPrecificacao[op.id_departamento] = {
                        id_departamento: op.id_departamento,
                        dose_gesto_vacinal: 0,
                        taxa_de_gestao: 0,
                    }
                    operadorDistribuicao[op.id_departamento] = {
                        id_vacina: vacina.id_vacina,
                        id_grupo: grupo.id_grupo,
                        id_departamento: op.id_departamento,
                        qtd_dose: 0
                    }
                });

                if (precificacao == undefined) {
                    state.precificacao[vacina.id_vacina][grupo.id_grupo] = {
                        id_vacina: vacina.id_vacina,
                        id_grupo: grupo.id_grupo,
                        valor_empresa: 0,
                        operador: operadorPrecificacao,
                    }
                }

                if (distribuicao == undefined) {
                    state.distribuicao[vacina.id_vacina][grupo.id_grupo] = operadorDistribuicao
                }
            }
        });
    },
    setPrecificacaoVacinaGrupoEmpresa: (state, payload) => {
        let idVacina = payload.idVacina,
            idGrupo = payload.idGrupo,
            valor = payload.valor;

        if (idGrupo != undefined && valor != undefined && state.precificacao[idVacina] != undefined) {
            const precificacao = state.precificacao[idVacina][idGrupo];
            if (precificacao != undefined) {
                state.precificacao[idVacina][idGrupo].valor_empresa = helpers.converteValorMonetarioStringFloat(valor)
            }
        }
    },
    setPrecificacaoVacinaGrupoOperador: (state, payload) => {
        let idVacina = payload.idVacina,
            idGrupo = payload.idGrupo,
            idDepartamento = payload.idDepartamento,
            campoAlteracao = payload.campoAlteracao,
            valor = payload.valor;

        if (state.precificacao[idVacina] != undefined) {
            const precificacao = state.precificacao[idVacina][idGrupo];
            if (precificacao != undefined) {
                state.precificacao[idVacina][idGrupo].operador[idDepartamento][campoAlteracao] = helpers.converteValorMonetarioStringFloat(valor)
            }
        }
    },
    setQtdDistribuicaoVacinaGrupoOperador: (state, payload) => {
        let idVacina = payload.idVacina,
            idGrupo = payload.idGrupo,
            idDepartamento = payload.idDepartamento,
            qtd = payload.qtd;

        if (state.distribuicao[idVacina] != undefined) {
            const distribuicao = state.distribuicao[idVacina][idGrupo];
            if (distribuicao != undefined) {
                state.distribuicao[idVacina][idGrupo][idDepartamento].qtd_dose = qtd
            }
        }
    },
    setDocumentoVacinaGrupo: (state, payload) => {
        let idVacina = payload.idVacina,
            idGrupo = payload.idGrupo,
            arquivo = payload.arquivo

        const precificacao = state.documentos[idVacina][idGrupo];
        if (precificacao != undefined) {
            let idCampanhaGrupoDocumento = arquivo.id_campanha_grupo_documento != undefined
                ? arquivo.id_campanha_grupo_documento
                : helpers.geraNumeroAleatorio();

            let documento = {
                ...arquivo,
                id_campanha_grupo_documento: idCampanhaGrupoDocumento,
                id_vacina: idVacina,
                id_grupo: idGrupo,
            }
            state.documentos[idVacina][idGrupo].push(documento)
        }
    },
    deleteDocumentoVacinaGrupo(state, payload) {
        let idVacina = payload.idVacina,
            idGrupo = payload.idGrupo,
            idGrupoDocumento = payload.idGrupoDocumento

        const documentos = state.documentos[idVacina][idGrupo]
        if (documentos) {
            state.documentos[idVacina][idGrupo] = documentos.filter(objeto => objeto.id_grupo_documento !== idGrupoDocumento);
        }
    },
    setResponse(state, payload) {
        state.response = {
            status: payload.status,
            message: payload.message,
            idCampanha: payload.idCampanha
        };
    },
    atualizaPrecificacaoEmModoEdicao(state, payload) {
        payload.forEach((campanhaVacina) => {
            try {
                let idVacina = campanhaVacina.id_vacina;
                campanhaVacina.estimativa.forEach((grupo) => {
                    let idGrupo = grupo.id_grupo;
                    if (state.precificacao[idVacina][idGrupo]) {
                        state.precificacao[idVacina][idGrupo].valor_empresa = grupo.valor_dose;


                        grupo.campanha_grupo_preco.forEach((operador) => {
                            let idDepartamento = operador.id_departamento;
                            state.precificacao[idVacina][idGrupo].operador[idDepartamento].dose_gesto_vacinal = operador.valor_dose;
                            state.precificacao[idVacina][idGrupo].operador[idDepartamento].taxa_de_gestao = operador.valor_taxa;
                        })
                    }
                })
            } catch (error) {
            }
        })
    },
}