export default {
    ADMIN_DN: 1,
    ADMIN_DR: 2,
    SAUDE_REGIAO: 3,
    MERCADO_DR: 4,
    ADMIN_UNIDADE: 5,
    VACINADOR_PADRAO: 6,
    ADMIN_EMPRESA: 7,
    VACINADOR_ADMIN: 8,
    SAUDE_DN: 9,
    SAUDE_DR: 10,
    SAUDE_UO: 11
}