import { state } from './state'
import { mutationsFunctions } from './mutationsFunctions'
import { geraPayload } from './functions'
const moment = require('moment');

const mutations = {
    // helpers 
    SET_INIT: mutationsFunctions.setInit,
    // RESET: mutationsFunctions.reset,
    SET_UPDATED_AT: mutationsFunctions.setUpdatedAt,

    // form
    SET_FORM_DESABILITAR_ENVIO: mutationsFunctions.setFormDesabilitarEnvio,
    SET_FORM_VACINAS: mutationsFunctions.setFormVacinas,
    SET_FORM_DOENCAS: mutationsFunctions.setFormDoencas,
    SET_FORM_GRUPOS: mutationsFunctions.setFormGrupos,

    // payload
    SET_DOENCA_SELECIONADA: mutationsFunctions.setDoencaSelecionada,
    SET_VACINAS_SELECIONADA: mutationsFunctions.setVacinasSelecionada,
    SET_QTD_ADESOES: mutationsFunctions.setQtdAdesoes,
    SET_GRUPOS_SELECIONADO: mutationsFunctions.setGruposSelecionadoPorVacina,
    SET_DESCRICAO: mutationsFunctions.setDescricao,
    SET_DATA_INICIO_SELECIONADO: mutationsFunctions.setDataInicioSelecionado,
    SET_DATA_TERMINO_SELECIONADO: mutationsFunctions.setDataTerminoSelecionado,
    DELETE_GRUPO_SELECIONADO: mutationsFunctions.deleteGrupoSelecionado,
    SET_PRECIFICACAO_VACINA_GRUPO_EMPRESA: mutationsFunctions.setPrecificacaoVacinaGrupoEmpresa,
    SET_PRECIFICACAO_VACINA_GRUPO_OPERADOR: mutationsFunctions.setPrecificacaoVacinaGrupoOperador,
    SET_QTD_DISTRIBUICAO_VACINA_GRUPO_OPERADOR: mutationsFunctions.setQtdDistribuicaoVacinaGrupoOperador,
    SET_DOCUMENTO_VACINA_GRUPO: mutationsFunctions.setDocumentoVacinaGrupo,
    SET_RESPONSE: mutationsFunctions.setResponse,
    ATUALIZA_PRECIFICACAO_EM_MODO_EDICAO: mutationsFunctions.atualizaPrecificacaoEmModoEdicao,
    // remove_grupo
    DELETE_DOCUMENTO_VACINA_GRUPO: mutationsFunctions.deleteDocumentoVacinaGrupo,
    RESET_VACINAS: mutationsFunctions.resetVacinas,
    // errors
};

const actions = {
    setMock: ({ commit, state }) => {
        setTimeout(function () {
            commit('SET_DESCRICAO', 'Campanha Nacional Mock')
            commit('SET_DATA_INICIO_SELECIONADO', moment().format('YYYY-MM-DD'));
            commit('SET_DATA_TERMINO_SELECIONADO', moment().add(30, 'days').format('YYYY-MM-DD'));
            commit('SET_DOENCA_SELECIONADA', 1)
            commit('SET_VACINAS_SELECIONADA', [state.form.vacinas[0]])
        }, 1000);
    },
    setInit: ({ commit }, payload) => {
        commit('SET_INIT', payload)
    },
    setUpdatedAt: ({ commit }) => {
        commit('SET_UPDATED_AT')
    },
    resetVacinas: ({ commit }) => {
        commit('RESET_VACINAS')
    },
    setFormDesabilitarEnvio: ({ commit }, payload) => {
        commit('SET_FORM_DESABILITAR_ENVIO', payload)
        commit('SET_UPDATED_AT')
    },
    setFormDoencas: ({ commit }, payload) => {
        commit('SET_DOENCA_SELECIONADA', null)
        commit('SET_FORM_DOENCAS', payload)
        commit('SET_UPDATED_AT')
    },
    setFormVacinas: ({ commit }, payload) => {
        commit('SET_VACINAS_SELECIONADA', [])
        commit('SET_FORM_VACINAS', payload)
        commit('SET_UPDATED_AT')
    },
    setFormGrupos: ({ commit }, payload) => {
        commit('SET_FORM_GRUPOS', payload)
        commit('SET_UPDATED_AT')
    },
    setDescricao: ({ commit }, payload) => {
        commit('SET_DESCRICAO', payload)
        commit('SET_UPDATED_AT')
    },
    setDoencaSelecionada: ({ commit }, payload) => {
        commit('SET_DOENCA_SELECIONADA', payload)
        commit('SET_UPDATED_AT')
    },
    setVacinasSelecionada: ({ commit }, payload) => {
        commit('SET_VACINAS_SELECIONADA', payload)
        commit('SET_UPDATED_AT')
    },
    setQtdAdesoes: ({ commit }, payload) => {
        commit('SET_QTD_ADESOES', payload)
        commit('SET_UPDATED_AT')
    },
    setDataInicioSelecionado: ({ commit }, payload) => {
        commit('SET_DATA_INICIO_SELECIONADO', payload)
        commit('SET_UPDATED_AT')
    },

    setDataTerminoSelecionado: ({ commit }, payload) => {
        commit('SET_DATA_TERMINO_SELECIONADO', payload)
        commit('SET_UPDATED_AT')
    },
    setGruposSelecionadoPorVacina: ({ commit }, payload) => {
        payload.localCommit = commit
        commit('SET_GRUPOS_SELECIONADO', payload)
        commit('SET_UPDATED_AT', ['grupos'])
    },
    deleteGrupoSelecionado: ({ commit }, payload) => {
        commit('DELETE_GRUPO_SELECIONADO', payload)
        commit('SET_UPDATED_AT', [
            'precificacao',
            'distribuicao',
            'grupos',
        ])
    },
    setPrecificacaoVacinaGrupoEmpresa: ({ commit }, payload) => {
        commit('SET_PRECIFICACAO_VACINA_GRUPO_EMPRESA', payload)
        commit('SET_UPDATED_AT', ['precificacao'])
    },
    setPrecificacaoVacinaGrupoOperador: ({ commit }, payload) => {
        commit('SET_PRECIFICACAO_VACINA_GRUPO_OPERADOR', payload)
        commit('SET_UPDATED_AT', ['precificacao'])
    },
    setQtdDistribuicaoVacinaGrupoOperador: ({ commit }, payload) => {
        commit('SET_QTD_DISTRIBUICAO_VACINA_GRUPO_OPERADOR', payload)
        commit('SET_UPDATED_AT', ['distribuicao'])
    },
    setDocumentoVacinaGrupo: ({ commit }, payload) => {
        commit('SET_DOCUMENTO_VACINA_GRUPO', payload)
        commit('SET_UPDATED_AT', ['documentos'])
    },
    deleteDocumentoVacinaGrupo: ({ commit }, payload) => {
        commit('DELETE_DOCUMENTO_VACINA_GRUPO', payload)
        commit('SET_UPDATED_AT', ['documentos'])
    },
    setResponse: ({ commit }, payload) => {
        commit('SET_RESPONSE', payload)
    },
    atualizaPrecificacaoEmModoEdicao: ({ commit }, payload) => {
        commit('ATUALIZA_PRECIFICACAO_EM_MODO_EDICAO', payload)
    },
}

const getters = {
    state: (state) => state,
    payload: (state) => {
        return geraPayload(state);
    },
    getPreencheuDadosMinimos: (state) => {
        return (
            state.descricao != null &&
            state.doenca != null &&
            state.vacinas.length > 0 &&
            state.data_inicio != null &&
            state.data_termino != null
        );
    },
    getFormDesabilitarEnvio: (state) => state.form.desabilitarEnvio,
    getFormDoencas: (state) => state.form.doencas,
    getFormVacinas: (state) => state.form.vacinas,
    getFormGrupos: (state) => state.form.grupos,
    getDescricao: (state) => state.descricao,
    getDoencaSelecionada: (state) => state.doenca,
    getVacinasSelecionada: (state) => state.vacinas,
    getQtdAdesoes: (state) => state.qtd_adesoes,
    getGruposSelecionado: (state) => state.grupos,
    getDataInicio: (state) => state.data_inicio,
    getDataTermino: (state) => state.data_termino,
    getPrecificacao: (state) => state.precificacao,
    getDistribuicao: (state) => state.distribuicao,
    getDocumentos: (state) => state.documentos
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}