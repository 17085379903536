import { io } from "socket.io-client";

const socket = io(process.env.VUE_APP_SOCKET_URL, {
    autoConnect: false,
    reconnectionDelayMax: 10000,
});

socket.onAny(() => {});

export default socket;
