export const geraPayload = state => {
  const adesaoEnderecoEmpresa = {
    idCampanha: state.campaignAdesaoNacional.id_campanha,
    idGrupo: state.companyAdesaoNacional.id_grupo,
    idGrupoOperador: state.operadorAdesaoNacional.id_grupo_operador,
    enderecos: [],
  }
  let enderecoDistribuicao = null

  state.addressCompany.forEach(empresa => {
    empresa.empresa_endereco.forEach(endereco => {
      enderecoDistribuicao = state.distribuicao.empresas[empresa.id_empresa][endereco.id_empresa_endereco]
      const vacinas = []

      if (enderecoDistribuicao.length > 0) {
        Object.keys(enderecoDistribuicao).forEach(vacinaId => {
          let idAdesaoVacina = null
          if (
            state.adesoesEnderecoEmpresa != undefined
              && state.adesoesEnderecoEmpresa[empresa.id_empresa] != undefined
              && state.adesoesEnderecoEmpresa[empresa.id_empresa][endereco.id_empresa_endereco] != undefined
              && state.adesoesEnderecoEmpresa[empresa.id_empresa][endereco.id_empresa_endereco].vacinas[vacinaId] != undefined
          ) {
            idAdesaoVacina = state.adesoesEnderecoEmpresa[empresa.id_empresa][endereco.id_empresa_endereco].vacinas[vacinaId].idAdesaoVacina
          }

          vacinas.push({
            idVacina: Number(vacinaId),
            qtdDoses: enderecoDistribuicao[vacinaId],
            idAdesaoVacina,
          })
        })

        let idAdesao = null
        if (
          state.adesoesEnderecoEmpresa != undefined
            && state.adesoesEnderecoEmpresa[empresa.id_empresa] != undefined
            && state.adesoesEnderecoEmpresa[empresa.id_empresa][endereco.id_empresa_endereco] != undefined
        ) {
          idAdesao = state.adesoesEnderecoEmpresa[empresa.id_empresa][endereco.id_empresa_endereco].idAdesao
        }

        adesaoEnderecoEmpresa.enderecos.push({
          idAdesao,
          idEmpresa: empresa.id_empresa,
          idEmpresaEndereco: endereco.id_empresa_endereco,
          idUnidade: endereco.id_unidade,
          vacinas,
        })
      }
    })
  })

  return adesaoEnderecoEmpresa
}
