<template><div id="socketio"></div></template>

<script>
import SocketIO from "@/socketio";
import moment from "moment";
import { isUserLoggedIn, getUserData } from "@/auth/utils";
import { TIPONOTIFICACAO } from "@/enum/tipo-notificacao";
import {getRulesAuthUser} from "@/views/custom-pages/gerenciarUsuarios/requests";
export default {
  data() {
    return {
      userLogged: null,
      SocketIOConnectError: "connect_error",
      SocketIOPrivateMessage: "private message",
    };
  },
  beforeMount() {
    if (isUserLoggedIn()) {
      this.userLogged = {
        id_usuario: getUserData().id,
        email: getUserData().email,
        access_token: getUserData().auth.access_token,
      };
      this.consultaMinhasNotificacoes();
      this.connect();
    }
  },
  methods: {
    connect() {
      SocketIO.auth = this.userLogged;
      this.$store.dispatch(
        "notifications/setUserId",
        this.userLogged.id_usuario
        );
        SocketIO.connect();
    },
    disconnect() {
      SocketIO.off(this.SocketIOConnectError);
    },
    consultaMinhasNotificacoes() {
      this.$http
        .get(this.$api.getNotificacoesVigentes(), {
          params: {
            vigencia: moment().format("YYYY-MM-DD"),
          },
        })
        .then((res) => {
          this.$store.dispatch(
            "notifications/setItens",
            res.status == 204 ? [] : res.data.data
          );
        });
    },
    userCompanyRules(data) {
      data.forEach(item => {
        if(!item.notificado && item.notificacao.id_tipo_notificacao == TIPONOTIFICACAO.AGENDAMENTO_DISPONIVEL) {
          getRulesAuthUser()
            .then((res) => {
              this.$store.commit('sessions/SET_USER_ABILITY', res.data )
              this.$ability.update(res.data)
            })
        }
      })
    }

  },
  created() {
    SocketIO.on(this.SocketIOPrivateMessage, (_err) => {
      this.consultaMinhasNotificacoes();
    });
  },
  destroyed() {
    this.disconnect();
  },
};
</script>
