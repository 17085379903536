import cepSearch from 'cep-promise'
import { getUserData } from "@/auth/utils";

export default {
  searchCep(cep, form, formError, cities) {
    const { departamento } = getUserData()

    cepSearch(cep).then(data => {
      if (data.state === departamento.uf) {
        form.address = data.street
        form.neighborhood = data.neighborhood
        form.city = null

        cities.map(city => {
          if (city.nome === data.city) {
            form.city = city.id_cidade
          }
        })
        return
      }

      formError.cep = 'Este CEP não é do mesmo estado da DR'

      form.address = null
      form.neighborhood = null
      form.city = null
    }).catch(error => {
      if (error.type === 'service_error') {
        formError.cep = 'CEP não encontrado'
        return
      }

      formError.cep = 'CEP inválido'
    })
  },
}
