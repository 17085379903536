import helpers from '@/helpers'
import { geraPayload } from './functions'

export const mutationsFunctions = {
  setInit: (state, payload) => {
    state.idUsuarioLogado = payload.idUsuarioLogado
    state.campaignAdesaoNacional = null
    state.companyAdesaoNacional = null
    state.operadorAdesaoNacional = null
    state.addressCompany = {}
    state.updatedAt = helpers.geraNumeroAleatorio()
  },
  setUpdatedAt: state => {
    state.updatedAt = helpers.geraNumeroAleatorio()
  },
  setChooseCampaignAdesaoNacional: (state, campaignAdesaoNacional) => {
    state.campaignAdesaoNacional = campaignAdesaoNacional
  },
  setChooseCompanyAdesaoNacional: (state, companyAdesaoNacional) => {
    state.companyAdesaoNacional = companyAdesaoNacional
  },
  setChooseOperadorAdesaoNacional: (state, operadorAdesaoNacional) => {
    state.operadorAdesaoNacional = operadorAdesaoNacional
  },
  setAdesoesEnderecoEmpresa: (state, adesoesEnderecoEmpresa) => {
    state.adesoesEnderecoEmpresa = adesoesEnderecoEmpresa
  },
  setAddressCompanyAdesaoNacional: (state, addressCompany) => {
    state.addressCompany = addressCompany

    if (addressCompany == null) {
      state.distribuicao = {
        totalDoses: {
          totalGrupo: 0,
          empresas: {},
        },
        empresas: {},
      }
    } else {
      addressCompany.forEach(company => {
        state.distribuicao.empresas[company.id_empresa] = []
        company.empresa_endereco.forEach(address => {
          state.distribuicao.empresas[company.id_empresa][address.id_empresa_endereco] = []
        })
      })
    }
  },

  recalcularDosesEnderecoEmpresa: (state, payload) => {
    let dosesGrupo = 0
    const dosesEmpresa = {}

    state.distribuicao.empresas[payload.idEmpresa][payload.idEnderecoEmpresa][payload.idVacina] = payload.value

    Object.keys(state.distribuicao.empresas).forEach(companyId => {
      const company = state.distribuicao.empresas[companyId]
      dosesEmpresa[companyId] = 0

      Object.keys(company).forEach(addressId => {
        const addressDose = state.distribuicao.empresas[companyId][addressId]

        Object.keys(addressDose).forEach(vacinaId => {
          const vacinaDose = state.distribuicao.empresas[companyId][addressId][vacinaId]

          dosesGrupo += Number(vacinaDose)
          dosesEmpresa[companyId] += Number(vacinaDose)
        })
      })
    })

    state.distribuicao.totalDoses.totalGrupo = dosesGrupo
    state.distribuicao.totalDoses.empresas = dosesEmpresa
  },

  setPayloadAdesaoNacional: (state, payload) => {
    state.payloadAdesaoNacional = geraPayload(state)
  },
}
