const state = {
    idUnidade: null,
    acronymUnidade: null
}

const mutations = {
    SET_ID_UNIDADE(state, data){
        state.idUnidade = data
    },
    SET_ACRONYM_UNIDADE(state, data){
        state.acronymUnidade = data
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
