import { state } from './state'
import { formataDadosParaSalvarPedido } from './functions'
import { mutationsFuncitions } from './mutationsFuncitions'


const mutations = {
    INIT: mutationsFuncitions.init,
    RESET: mutationsFuncitions.reset,
    RESET_PERSISTENCIA: mutationsFuncitions.resetPersistencia,
    INIT_EMPRESAS_DOSES: mutationsFuncitions.initEmpresasDoses,
    CHANGE_UPDATED_AT: mutationsFuncitions.changeUpdatedAt,

    HANDLE_VISIBILIDADE_OBSERVACAO_MODAL: mutationsFuncitions.handleVisibilidadeObservacaoModal,
    LIMPAR_CAMPOS_DOSES_VACINAS: mutationsFuncitions.limparCamposDosesVacinas,
    PADRONIZAR_SEQUENCIA_EMPRESAS: mutationsFuncitions.padronizarSequenciaEmpresas,
    RECALCULAR_CARRINHO_EMPRESA: mutationsFuncitions.recalcularCarrinhoEmpresa,

    SET_CHOOSE_CAMPAIGN: mutationsFuncitions.setChooseCampaign,
    SET_CHOOSE_COMPANY: mutationsFuncitions.setChooseCompany,
    SET_CHOOSE_UNIT: mutationsFuncitions.setChooseUnit,
    SET_EMPRESAS: mutationsFuncitions.setEmpresas,
    SET_RESUMO: mutationsFuncitions.setResumo,
    SET_VACINAS: mutationsFuncitions.setVacinas,
    SET_TERMO: mutationsFuncitions.setTermo,
    SET_INFORMACOES_PERSISTENCIA: mutationsFuncitions.setInformacoesPersistencia,
    SET_CHOOSE_ORDER: mutationsFuncitions.setChooseOrder,
    SET_CHOOSE_OBSERVACAO: mutationsFuncitions.setChooseObservacao,
    SET_VALIDACAO_DOSES_EMPRESA: mutationsFuncitions.setValidacaoDosesEmpresa,

    ADD_EMPRESA: mutationsFuncitions.addEmpresa,

    UPDATE_EMPRESAS_SEQUENCIA: mutationsFuncitions.updateEmpresasSequencia,
    UPDATE_EMPRESA_DOSES_VALORES: mutationsFuncitions.updateEmpresaDosesValores,
    UPDATE_EMPRESA_OBSERVACAO: mutationsFuncitions.updateEmpresaObservacao,
    UPDATE_EMPRESA_POR_ID_EMPRESA: mutationsFuncitions.updateEmpresaPorIdEmpresa,

    DELETE_EMPRESA: mutationsFuncitions.deleteEmpresa,
};

const actions = {
    resetAdesao(context) {
        context.commit('RESET')
    },
    resetPersistencia(context) {
        context.commit('RESET_PERSISTENCIA')
    },
    initAdesao(context, idUsuario = null) {
        context.commit('INIT', idUsuario)
        context.commit('RESET_PERSISTENCIA')
        context.commit('CHANGE_UPDATED_AT')
    },
    initEmpresasDoses(context, payload) {
        context.commit('INIT_EMPRESAS_DOSES', payload)
        context.commit('SET_RESUMO', true)
    },
    setVacinas(context, payload) {
        context.commit('SET_VACINAS', payload)
    },
    setEmpresas(context, payload) {
        context.commit('SET_EMPRESAS', payload)
        context.commit('CHANGE_UPDATED_AT')
    },
    setTermo(context, payload) {
        context.commit('SET_TERMO', payload)
    },
    addEmpresa(context, payload) {
        context.commit('ADD_EMPRESA', payload)
        context.commit('CHANGE_UPDATED_AT')
    },
    deleteEmpresa(context, payload) {
        context.commit('DELETE_EMPRESA', payload)
        context.commit('PADRONIZAR_SEQUENCIA_EMPRESAS');
        context.commit('SET_RESUMO')
    },
    updateEmpresasSequencia(context, payload) {
        context.commit('UPDATE_EMPRESAS_SEQUENCIA', payload)
        context.commit('PADRONIZAR_SEQUENCIA_EMPRESAS');
        context.commit('SET_RESUMO')
    },
    updateDose(context, payload) {
        context.commit('UPDATE_EMPRESA_DOSES_VALORES', payload)
        context.commit('RECALCULAR_CARRINHO_EMPRESA', payload)
        context.commit('SET_RESUMO')
        context.commit('CHANGE_UPDATED_AT')
    },
    recalcularCarrinho(context) {
        context.commit('RECALCULAR_CARRINHO_EMPRESA')
    },
    limparCamposDosesVacinas(context, payload) {
        context.commit('LIMPAR_CAMPOS_DOSES_VACINAS', payload)
        context.commit('RECALCULAR_CARRINHO_EMPRESA', payload)
        context.commit('SET_RESUMO')
    },
    handleVisibilidadeObservacaoModal(context, payload) {
        context.commit('HANDLE_VISIBILIDADE_OBSERVACAO_MODAL', payload)
    },
    updateEmpresaObservacao(context, payload) {
        context.commit('UPDATE_EMPRESA_OBSERVACAO', payload)
    },
    setInformacoesPersistencia(context, payload) {
        context.commit('SET_INFORMACOES_PERSISTENCIA', payload)
    },
    setChooseOrder(context, payload) {
        context.commit('RESET_PERSISTENCIA')
        context.commit('SET_CHOOSE_ORDER', payload)
    },
    setChooseCampaign(context, payload) {
        context.commit('SET_CHOOSE_CAMPAIGN', payload)
    },
    setChooseUnit(context, payload) {
        context.commit('SET_CHOOSE_UNIT', payload)
    },
    setChooseObservacao(context, payload) {
        context.commit('SET_CHOOSE_OBSERVACAO', payload)
    },
    setValidacaoDosesEmpresa(context, payload = null) {
        context.commit('SET_VALIDACAO_DOSES_EMPRESA', payload)
    }
}

const getters = {
    getEmpresas: function(state) {
        return state.empresas;
    },
    getVacinas: function(state) {
        return state.vacinas;
    },
    getIdUsuarioProcessamento: function(state) {
        return state.idUsuarioProcessamento;
    },
    getChooseCampaign(state) {
        return state.chooseCampaign;
    },
    getChooseUnit(state) {
        return state.chooseUnit;
    },
    getChooseCompany(state) {
        return state.chooseCompany
    },
    getDesabilitarEnvio(state) {
        return state.desabilitarEnvio;
    },
    getResumoVacinas(state) {
        return state.resumo
    },
    getObservacoesDasEmpresas(state) {
        return state.observacoesDasEmpresas;
    },
    getDadosPost: (state) => (rascunho) => {
        return formataDadosParaSalvarPedido(state, rascunho);
    },
    getInformacoesPersistencia(state) {
        return state.persistencia;
    },
    getChooseOrder(state) {
        return state.chooseOrder;
    },
    getValidacaoDosesEmpresa(state) {
        return state.validacaoDosesEmpresa;
    },
    getState(state) {
        return state;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}