const generateUpdatedAt = () => Math.random();
import api from "@/router/rotasAPI";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { TIPONOTIFICACAO } from "@/enum/tipo-notificacao";

const consultaMinhasNotificacoes = (state) => {
  axiosIns
    .get(api.getNotificacoesVigentes(), {
      params: {
        vigencia: moment().format("YYYY-MM-DD"),
      },
    })
    .then((res) => {
      state.notifications.itens = res.status == 204 ? [] : res.data.data;
      state.notifications.updated_at = generateUpdatedAt();
    });
};

const getNotificationsFiltered = (
  notifications,
  idTipoNotificacao = null,
  idTipoNotificacaoExclude = []
) => {
  let itens = notifications.itens != null ? notifications.itens : [];
  if (idTipoNotificacao != null) {
    itens = itens.filter(
      (notification) => notification.id_tipo_notificacao == idTipoNotificacao
    );
  }

  if (idTipoNotificacaoExclude.length > 0 && itens != null) {
    idTipoNotificacaoExclude.forEach((tipoNotificacao) => {
      itens = itens.filter(
        (notification) => notification.id_tipo_notificacao != tipoNotificacao
      );
    });
  }

  return {
    ...notifications,
    ...{ itens },
  };
};

const state = {
  notifications: {
    user_id: null,
    itens: [],
    updated_at: null,
  },
};

const mutations = {
  RESET_STATE(state) {
    state.notifications = {
      user_id: null,
      itens: [],
    };
  },
  SET_UPDATED_AT(state) {
    state.notifications.updated_at = generateUpdatedAt();
  },
  SET_USER_ID(state, user_id) {
    state.notifications.user_id = user_id;
  },
  SET_ITENS(state, itens) {
    state.notifications.itens = itens;
  },
  UPDATE_USER_NOTIFICATION_AS_READ(state, idNotificacaoUsuario) {
    axiosIns
      .patch(api.viewNotificacaoNaoVisualizada(idNotificacaoUsuario))
      .then(() => {
        consultaMinhasNotificacoes(state);
      });
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
    commit("SET_UPDATED_AT");
  },
  setUserId({ commit }, user_id) {
    commit("SET_USER_ID", user_id);
    commit("SET_ITENS", null);
    commit("SET_UPDATED_AT");
  },
  setItens({ commit }, itens) {
    commit("SET_ITENS", itens);
    commit("SET_UPDATED_AT");
  },
  updateUserNotificationAsRead({ commit }, { idNotificacaoUsuario }) {
    commit("UPDATE_USER_NOTIFICATION_AS_READ", idNotificacaoUsuario);
    commit("SET_UPDATED_AT");
  },
};

const getters = {
  getUpdatedAt: (state) => {
    return state.notifications.updated_at;
  },
  getNotifications: (state) => {
    return getNotificationsFiltered(state.notifications);
  },
  getNotificationsAviso: (state) => {
    return getNotificationsFiltered(state.notifications, TIPONOTIFICACAO.AVISO);
  },
  getNotificationsImportacao: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.IMPORTACAO_COLABORADORES_CONCLUIDA
    );
  },
  getNotificationsEmpresaCNAEDivergente: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.EMPRESA_CNAE_DIVERGENTE
    );
  },
  getNotificationsEmpresaCadastroCompleto: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.EMPRESA_CADASTRO_COMPLETO
    );
  },
  getNotificationsCampanhaIniciada: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.CAMPANHA_INICIADA
    );
  },
  getNotificationsCampanhaProximaTerminar: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.CAMPANHA_PROXIMA_TERMINAR
    );
  },
  getNotificationsAdesaoPelaEmpresa: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.ADESAO_PELA_EMPRESA
    );
  },
  getNotificationsAdesaoParaEmpresa: (state) => {
    return getNotificationsFiltered(
      state.notifications,
      TIPONOTIFICACAO.ADESAO_PARA_EMPRESA
    );
  },
  getNotificationsBell: (state) => {
    let notificacaoTipoModal = [];
    return getNotificationsFiltered(
      state.notifications,
      null,
      notificacaoTipoModal
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
