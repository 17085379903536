import helpers from '@/helpers'

export const state = {
    idUsuarioProcessamento: null,
    empresas: [],
    vacinas: [],
    updatedAt: helpers.geraNumeroAleatorio(),
    chooseCampaign: null,
    chooseUnit: null,
    chooseOrder: null,
    carrinho: null,
    desabilitarEnvio: true,
    resumo: {
        updatedAt: helpers.geraNumeroAleatorio(),
        vacinas: null
    },
    chooseCompany: null,
    estadoInicial: {
        carrinhoVacina: {},
        vacinas: [],
    },
    observacoesDasEmpresas: {
        showModal: false,
        empresaSelecionada: null,
        observacoes: {}
    },
    persistencia: {},
    validacaoDosesEmpresa: null,
    termo:[]
}

export const carrinhoEstrutura = {
    vacinas: [],
    doses: 0,
    valor: 0,
};