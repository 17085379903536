export const TIPONOTIFICACAO = {
    AVISO: 1,
    IMPORTACAO_COLABORADORES_CONCLUIDA: 2,
    EMPRESA_CNAE_DIVERGENTE: 3,
    EMPRESA_CADASTRO_COMPLETO: 4,
    CAMPANHA_INICIADA: 5,
    CAMPANHA_PROXIMA_TERMINAR: 6,
    ADESAO_PELA_EMPRESA: 7,
    ADESAO_PARA_EMPRESA: 8,
    AGENDAMENTO_DISPONIVEL: 15
}
