const state = {
    idAjuda: null,
    idCategoria: null,
    categoria: null
}

const mutations = {
    SET_ID_AJUDA(state,data){
        state.idAjuda = data
    },
    SET_ID_CATEGORIA(state,data){
        state.idCategoria = data
    },
    SET_DESCRICAO_CATEGORIA(state,data){
        state.categoria = data
    }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
