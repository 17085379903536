
export default {
    created () {
        var meta = document.createElement('meta');
        meta.setAttribute('name', 'robots');
        meta.setAttribute('content', 'noindex');
        meta.setAttribute('id', 'indexable');

        if(!document.getElementById('indexable')) {
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
    }
}