const state = {
    idDepartamento: null,
    acronymDepartamento: null
}

const mutations = {
    SET_ID_DEPARTAMENTO(state, data){
        state.idDepartamento = data
    },
    SET_ACRONYM_DEPARTAMENTO(state, data){
        state.acronymDepartamento = data
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
