const state = {
    idAgendamento: null,
}

const mutations = {
    SET_ID_AGENDAMENTO(state, data){
        state.idAgendamento = data
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
