const state = {
    departmentApiUser: null
}

const mutations = {
    SET_CHOOSE_DEPARTMENT_API_USER(state, data){
        state.departmentApiUser = data
    },
}

export default {
  namespaced: true,
  state,
  mutations,
}
