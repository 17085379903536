const state = {
    idAviso: null,
    perfisSelecionados: []
}

const mutations = {
    SET_ID_AVISO(state,data){
        state.idAviso = data
    },
    SET_PERFIS_SELECIONADOS(state,data){
        state.perfisSelecionados = data
    }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
