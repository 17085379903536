import api from '@/libs/api'
import rotasAPI from '@/router/rotasAPI'

const defaultCount = parseInt(process.env.VUE_APP_SESSION_EXPIRED) * 60

const state = {
  count: defaultCount,
  token: null,
  userData: {},
  refCount: null,
  clickNotAuthorized: 0,
}

const mutations = {
  DEFINE_LOGGED_USER(state, { token, userData }) {
    state.userData = userData
    state.token = token
  },
  LOGOUT_USER(state) {
    state.token = null
    state.userData = {}
  },
  START_COUNT(state) {
    if (state.token && state.userData) {
      clearInterval(state.refCount)
      state.count = defaultCount
      state.refCount = null

      state.refCount = setInterval(() => {
        state.count--
      }, 1000)
    }
  },
  STOP_COUNT(state) {
    clearInterval(state.refCount)
    state.count = defaultCount
    state.refCount = null
  },
  SET_COUNT(state, newCount) {
    state.count = newCount
  },
  SET_DEPARTMENT(state, newData) {
    state.userData.departamento = newData
  },
  SET_USER_NAME(state, newValue) {
    state.userData.fullName = newValue
  },
  SET_USER_COMPANY(state, newValue) {
    state.userData.empresa = newValue
  },
  SET_USER_UNIT(state, newValue) {
    state.userData.unidade = newValue
  },
  ADD_USER_COMPANY(state, newValue) {
    state.userData.empresas.push(newValue)
  },
  SET_USER_ABILITY(state, value) {
    state.userData.ability = value
  },
}

const actions = {
  login({ commit }, userData) {
    return new Promise((resolve, reject) => {
      api
        .post(rotasAPI.login(), userData)
        .then(response => {
          commit('DEFINE_LOGGED_USER', {
            token: response.data.auth.access_token,
            userData: response.data,
          })

          commit('START_COUNT')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  logout({ commit }) {
    commit('LOGOUT_USER')
    commit('STOP_COUNT')
  },
  startCount({ commit }) {
    commit('START_COUNT')
  },
  setCount({ commit }, newCount) {
    commit('SET_COUNT', newCount)
  },
}

const getters = {
  isLoggedIn: state => Boolean(state.token && state.userData),
  getUserData: state => state.userData,
  getCount: state => () => state.count,
  getDepartamento: state => state.userData.departamento,
  getAuthUserProfiles: state => state.userData.perfis,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
