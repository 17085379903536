/* eslint-disable no-shadow */
const state = {
  purchaseView: null,
  purchaseUpdate: null,
}

const mutations = {
  setPurchaseView(state, data) {
    state.purchaseView = data
  },
  setPurchaseUpdate(state, data) {
    state.purchaseUpdate = data
  },
}

const getters = {
  getPurchaseView: state => state.purchaseView,
  getPurchaseUpdate: state => state.purchaseUpdate,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
