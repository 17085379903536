import { state } from './state'
import { mutationsFunctions } from './mutationsFunctions'

const mutations = {
  // helpers
  SET_INIT: mutationsFunctions.setInit,
  // RESET: mutationsFunctions.reset,
  SET_UPDATED_AT: mutationsFunctions.setUpdatedAt,

  // payload
  SET_CHOOSE_CAMPAIGN_ADESAO_NACIONAL: mutationsFunctions.setChooseCampaignAdesaoNacional,
  SET_CHOOSE_COMPANY_ADESAO_NACIONAL: mutationsFunctions.setChooseCompanyAdesaoNacional,
  SET_CHOOSE_OPERADOR_ADESAO_NACIONAL: mutationsFunctions.setChooseOperadorAdesaoNacional,
  SET_PAYLOAD_ADESAO_NACIONAL: mutationsFunctions.setPayloadAdesaoNacional,

  SET_ADDRESS_COMPANY_ADESAO_NACIONAL: mutationsFunctions.setAddressCompanyAdesaoNacional,

  RECALCULAR_DOSES_ENDERECO_EMPRESA: mutationsFunctions.recalcularDosesEnderecoEmpresa,

  SET_ADESOES_ENDERECO_EMPRESA: mutationsFunctions.setAdesoesEnderecoEmpresa,
}

const actions = {
  setInit: ({ commit }, payload) => {
    commit('SET_INIT', payload)
  },
  setUpdatedAt: ({ commit }) => {
    commit('SET_UPDATED_AT')
  },
  setChooseCampaignAdesaoNacional: ({ commit }, payload) => {
    commit('SET_CHOOSE_CAMPAIGN_ADESAO_NACIONAL', payload)
    commit('SET_UPDATED_AT')
  },
  setChooseCompanyAdesaoNacional: ({ commit }, payload) => {
    commit('SET_CHOOSE_COMPANY_ADESAO_NACIONAL', payload)
    commit('SET_UPDATED_AT')
  },
  setChooseOperadorAdesaoNacional: ({ commit }, payload) => {
    commit('SET_CHOOSE_OPERADOR_ADESAO_NACIONAL', payload)
    commit('SET_UPDATED_AT')
  },
  setAddressCompanyAdesaoNacional: ({ commit }, payload) => {
    commit('SET_ADDRESS_COMPANY_ADESAO_NACIONAL', payload)
    commit('SET_UPDATED_AT')
  },
  updateDose: ({ commit }, payload) => {
    commit('RECALCULAR_DOSES_ENDERECO_EMPRESA', payload)
    commit('SET_UPDATED_AT')
  },
  setAdesoesEnderecoEmpresa: ({ commit }, payload) => {
    commit('SET_ADESOES_ENDERECO_EMPRESA', payload)
    commit('SET_UPDATED_AT')
  },
  setPayloadAdesaoNacional: ({ commit }, payload) => {
    commit('SET_PAYLOAD_ADESAO_NACIONAL', payload)
    commit('SET_UPDATED_AT')
  },
}

const getters = {
  state: state => state,
  getChooseCampaignAdesaoNacional: state => state.campaignAdesaoNacional,
  getChooseCompanyAdesaoNacional: state => state.companyAdesaoNacional,
  getChooseOperadorAdesaoNacional: state => state.operadorAdesaoNacional,
  getDistribuicaoDosesAdesaoNacional: state => state.distribuicao,
  getAdesoesEnderecoEmpresa: state => state.adesoesEnderecoEmpresa,
  getPayloadAdesaoNacional: state => state.payloadAdesaoNacional,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
