export default [
    {
        path: '/',
        name: 'start-page',
        component: () => import('@/views/custom-pages/unauthenticated/Initial.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        }
    },
    {
        path: '/em-breve',
        name: 'coming-soon-page',
        component: () => import('@/views/custom-pages/unauthenticated/EcommerceTemporary'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
        }
    },
    {
        path: '/calendario-campanhas',
        name: 'campaign-calendar',
        component: () => import('@/views/custom-pages/unauthenticated/CampaignCalendar.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
        }
    },
    {
        path: '/contato',
        name: 'contact',
        component: () => import('@/views/custom-pages/unauthenticated/Contact.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
        }
    },
    {
        path: '/faca-seu-orcamento',
        name: 'budget-contact',
        component: () => import('@/views/custom-pages/unauthenticated/BudgetContact.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
        }
    },
    {
        path: '/ajuda',
        name: 'pages-faq',
        component: () => import('@/views/custom-pages/GerenciarAjuda/Faq.vue')
    },
    {
        path: '/para-empresas/login/:errorEmail?',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        }
    },
    {
        path: '/para-empresas',
        name: 'auth-register',
        component: () => import('@/views/pages/authentication/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/para-empresas/senha',
        name: 'password-register',
        component: () => import('@/views/pages/authentication/RegisterPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/para-empresas/esqueci-minha-senha',
        name: 'auth-forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/para-empresas/esqueci-minha-senha/verificar-email/:email',
        name: 'email-sent',
        component: () => import('@/views/pages/authentication/EmailSent.vue'),
        props: true,
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/para-empresas/esqueci-minha-senha/redefinir/:codigo/:successEmail?',
        name: 'reset-password',
        component: () => import('@/views/pages/authentication/ResetPassword.vue'),
        props: true,
        meta: {
            layout: 'full',
            resource: 'AUTH',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/logs/laravel',
        name: 'log',
        component: () => import('@/views/custom-pages/log/log.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: false,
        },
    }
]
