const state = {
  idUsuario: null,
  emailForgotPassword: null,
  empresas: [],
}

const mutations = {
  SET_ID(state, data) {
    state.idUsuario = data
  },
  SET_EMAIL_FORGOT_PASSWORD(state, data) {
    state.emailForgotPassword = data
  },
  SET_EMPRESA_LIST(state, data) {
    state.empresas = data
  },
  ADD_EMPRESA_TO_LIST(state, data) {
    state.empresas.push(data)
  }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
