const state = {
    idVacina: null
}

const mutations = {
    SET_ID_VACINA(state, data){
        state.idVacina = data
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
