import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'
import provider from '@/store'

const baseURL = process.env.VUE_APP_APP_URL
const login = `${baseURL}/para-empresas/login`
const notAuthorized = `${baseURL}/nao-autorizado`

export const getProjectStatus = () => {
    return process.env.VUE_APP_CURRENT_ENV ? process.env.VUE_APP_CURRENT_ENV : "desenvolvimento";
}

export const clearStore = () => {
    provider.dispatch('sessions/logout').then()
}

export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole) {
        return { name: 'home' }
    }

    clearStore()
    return { name: 'auth-login' }
}

export const isUserLoggedIn = () => {
    return provider.getters['sessions/isLoggedIn']
}

export const getToken = () => {
    return provider.state.sessions.token
}

export const getUserData = () => {
    return provider.state.sessions.userData
}

export const startCount = () => {
    provider.dispatch('sessions/startCount').then()
}

export const redirectToNotAuthorized = () => {
    location.href = notAuthorized
}

export const logoutUser = () => new Promise((resolve, reject) => {
  localStorage.removeItem('firsCompanyRegister');

  axiosIns.get(rotasAPI.logout())
    .then(response => {
      clearStore()
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const logoutUserRedirectToLogin = () => {
    clearStore()
    location.href = login
}
