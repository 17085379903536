const state = {
    idEmpresa: null,
    estadosEmpresaState: null,
    cidadeEstadosEmpresaState: null,
    modalValorDiferenciadoRemoverState: {
        show: false,
        confirmado: false,
        cancelado: false,
    },
}

const mutations = {
    SET_ID_EMPRESA(state, data) {
        state.idEmpresa = data;
    },

    SET_ESTADOS_ENDERECO_EMPRESA(state, data) {
        state.estadosEmpresaState = data;
    },

    SET_CIDADE_ESTADOS_ENDERECO_EMPRESA(state, data) {
        state.cidadeEstadosEmpresaState = data;
    },

    SET_MODAL_VALOR_DIFERENCIADO_REMOVER(state, payload) {
        state.modalValorDiferenciadoRemoverState = {
            ...state.modalValorDiferenciadoRemoverState,
            ...payload,
        };
    }
}

export default {
    namespaced: true,
    state,
    mutations,
}
