const state = {
    idDoenca: null
}

const mutations = {
    SET_ID_DOENCA(state, data){
        state.idDoenca = data
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
