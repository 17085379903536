const state = {
    idPontoAtendimento: null,
    descricaoPontoAtendimento: null
}

const mutations = {
    SET_ID_PONTO_ATENDIMENTO(state, data){
        state.idPontoAtendimento = data
    },
    SET_DESCRICAO_PONTO_ATENDIMENTO(state, data){
        state.descricaoPontoAtendimento = data
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
