import helpers from '@/helpers'
export const state = {
    updatedAt: helpers.geraNumeroAleatorio(),
    idUsuarioLogado: null,
    descricao: null,
    doenca: null,
    vacinas: [],
    data_inicio: null,
    data_termino: null,
    grupos: {},
    precificacao: {},
    distribuicao: {},
    documentos: {},
    form: {
        doencas: [],
        vacinas: [],
        grupos: [],
        precificacao: {},
        distribuicao: {},
    },
    response: {
        status: null,
        message: [],
    },

    // utilizado para atualizar componente
    watchers: {
        formGrupos: helpers.geraNumeroAleatorio(),
        formVacinas: helpers.geraNumeroAleatorio(),
        vacinasSelecionada: helpers.geraNumeroAleatorio(),
        idUsuarioLogado: helpers.geraNumeroAleatorio(),
        descricao: helpers.geraNumeroAleatorio(),
        doenco: helpers.geraNumeroAleatorio(),
        vacina: helpers.geraNumeroAleatorio(),
        data_inicio: helpers.geraNumeroAleatorio(),
        data_termino: helpers.geraNumeroAleatorio(),
        grupos: helpers.geraNumeroAleatorio(),
        precificacao: helpers.geraNumeroAleatorio(),
        documentos: helpers.geraNumeroAleatorio(),
    }

}