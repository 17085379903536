import helpers from '@/helpers'

export const state = {
  updatedAt: helpers.geraNumeroAleatorio(),
  idUsuarioLogado: null,
  payloadAdesaoNacional: null,
  campaignAdesaoNacional: null,
  companyAdesaoNacional: null,
  operadorAdesaoNacional: null,
  addressCompany: {},
  adesoesEnderecoEmpresa: {},
  distribuicao: {
    totalDoses: {
      totalGrupo: 0,
      empresas: {},
    },
    empresas: {},
  },

}
