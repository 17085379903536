import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import errors from "./routes/errors";
import guestRoutes from "./routes/guest-routes";
import authenticatedRoutes from "./routes/authenticated-routes";
import adesoes from "@/router/routes/adesoes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/", redirect: { name: "home" } },
      ...errors,
      ...guestRoutes,
      ...adesoes,
      ...authenticatedRoutes,
    {
      path: "*",
      redirect: { name: "error-404" },
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()

  if (to.meta.resource === 'AUTH' && isLoggedIn) {
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (to.meta.resource === 'ACL') {
    if (!isLoggedIn) return next({ name: 'start-page' })

    if (!canNavigate(to)) {
      return next({ name: 'misc-not-authorized' })
    }
  }

  return next()
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
