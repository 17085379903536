import helpers from '@/helpers'

const formataPrecificacoesDistribuicoes = (objeto) => {
    return Object.values(objeto).reduce((acc, currentValue) => {
        const item = Object.values(currentValue);
        acc.push(...item);
        return acc;
    }, []);
}

export const geraPayload = (state) => {
    let vacinas = [],
        gruposPorVacina = [],
        precificacaoPorVacina = [],
        distribuicaoPorVacina = {},
        documentosPorVacinaGrupo = [];

    state.vacinas.forEach((vacina) => {
        vacinas.push(vacina.id_vacina);

        if (state.grupos[vacina.id_vacina] != undefined) {
            Object.values(state.grupos[vacina.id_vacina]).forEach((grupo) => {
                if (distribuicaoPorVacina[vacina.id_vacina] == undefined) {
                    distribuicaoPorVacina[vacina.id_vacina] = {}
                }

                // retorna os grupos por vacina
                gruposPorVacina.push({
                    id_vacina: vacina.id_vacina,
                    id_grupo: grupo.id_grupo,
                    removido: grupo.removido
                })
                // retorna as precificacoes por vacina > grupo
                let precificacao = state.precificacao[vacina.id_vacina][grupo.id_grupo];

                if (precificacao != undefined) {
                    Object.values(precificacao.operador).forEach((operador) => {
                        precificacaoPorVacina.push({
                            ...{
                                id_vacina: precificacao.id_vacina,
                                id_grupo: precificacao.id_grupo,
                                valor_empresa: precificacao.valor_empresa,
                            },
                            ...operador
                        })
                    })

                }

                let distribuicao = state.distribuicao[vacina.id_vacina][grupo.id_grupo];
                if (distribuicao != undefined) {
                    Object.values(distribuicao).forEach((item) => {
                        if (distribuicaoPorVacina[item.id_vacina][item.id_grupo] == undefined) {
                            distribuicaoPorVacina[item.id_vacina][item.id_grupo] = {
                                id_vacina: item.id_vacina,
                                id_grupo: item.id_grupo,
                                departamentos: []
                            }
                        }
                        distribuicaoPorVacina[vacina.id_vacina][grupo.id_grupo].departamentos.push(item)
                    });
                }

                let documentos = state.documentos[vacina.id_vacina][grupo.id_grupo];
                if (documentos != undefined) {
                    documentos.forEach((item) => {
                        documentosPorVacinaGrupo.push(item)
                    });
                }
            });
        }
    });

    const distribuicaoPorVacinaFormatado = formataPrecificacoesDistribuicoes(distribuicaoPorVacina)

    return {
        updated_at: state.updatedAt,
        descricao: state.descricao,
        doenca: state.doenca,
        data_inicio: state.data_inicio,
        data_termino: state.data_termino,
        vacinas: vacinas,
        grupos: gruposPorVacina,
        precificacoes: precificacaoPorVacina,
        distribuicoes: distribuicaoPorVacinaFormatado,
        documentos: documentosPorVacinaGrupo,
    }
}