const state = {
    idCampaign: null,
    descriptionCampaign: null,
    idCampaignEditAndShow: null,
    regionsConsulted: {},
}

const mutations = {
    SET_ID_CAMPAIGN(state, data) {
        state.idCampaign = data
    },
    SET_DESCRIPTION_CAMPAIGN(state, data) {
        state.descriptionCampaign = data
    },
    SET_ID_CAMPAIGN_EDIT_AND_SHOW(state, data) {
        state.idCampaignEditAndShow = data
    },
    SET_REGION_CONSULTED(state, data) {
        if (data.idRegion == null) {
            state.regionsConsulted = {};
        }
        else {
            state.regionsConsulted[data.idRegion] = data.units
        }
    }
}

const actions = {

}

const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
