import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBTooltip } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import titleMixin from './mixins/titleMixin';
import indexableMixin from './mixins/indexableMixin';
import { VueMaskFilter, VueMaskDirective } from 'v-mask';
import { VMoney } from 'v-money';

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import 'vue-select/dist/vue-select.css'
import VuePdfApp from 'vue-pdf-app'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

//custom
import api from '@/router/rotasAPI.js';
import helpers from '@/helpers';
import cepService from '@/Services/SearchCep';
import perfis from '@/regras/perfis.js';

//config bus event
export const busEvent = new Vue();

const plugin = {
  install () {
    Vue.prototype.$api = api
    Vue.prototype.$helpers = helpers
    Vue.prototype.$cep = cepService
    Vue.prototype.$perfil = perfis
  }
}
Vue.use(plugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Custom directives
Vue.filter('VMask', VueMaskFilter)
Vue.directive('mask', VueMaskDirective)
Vue.directive('b-tooltip', VBTooltip)
Vue.component('vue-pdf-app', VuePdfApp)
Vue.directive('money', VMoney)

//custom mixins
Vue.mixin(titleMixin);
Vue.mixin(indexableMixin);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
