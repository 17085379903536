export default [
  {
    path: '/nao-autorizado',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/nao-habilitado',
    name: 'misc-not-enabled',
    component: () => import('@/views/pages/miscellaneous/NotEnabled'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/sessao-expirada',
    name: 'disconnected',
    component: () => import('@/views/pages/miscellaneous/Disconnected.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pagina-nao-encontrada',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
]
