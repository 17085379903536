import helpers from '@/helpers'
import { carrinhoEstrutura } from './state'
import { somaVacinaDoseCampo, zerarDoses, formataEstruturaEmpresa, getResumoVacinasFormatada, getResumoPorVacinaEmpresa, handleEstimativasEmpresa, adicionaAtributoPossuiPrecificacao } from './functions'

export const mutationsFuncitions = {
    init(state, idUsuario = null, isReset = false) {
        state.idUsuarioProcessamento = idUsuario;
        state.empresas = [];
        state.vacinas = [];
        state.termo = [];
        state.carrinho = carrinhoEstrutura;
        state.desabilitarEnvio = true
        state.resumo = {
            updatedAt: helpers.geraNumeroAleatorio(),
            vacinas: null
        }
        state.observacoesDasEmpresas = {
            showModal: false,
            empresaSelecionada: null,
            observacoes: {}
        }

        if (isReset) {
            state.chooseCampaign = null
            state.chooseUnit = null
            state.chooseCompany = null
        }
        state.validacaoDosesEmpresa = null
    },
    reset(state) {
        mutationsFuncitions.init(state, null, true)
    },
    resetPersistencia(state) {
        state.persistencia = {
            status: null,
            mensagens: [],
            updatedAt: helpers.geraNumeroAleatorio()
        }
    },
    initEmpresasDoses(state, listaVacinas) {
        state.estadoInicial = {
            carrinhoVacina: {},
            vacinas: [],
        }

        listaVacinas.forEach((vacina) => {
            state.estadoInicial.carrinhoVacina[vacina.id_vacina] = {
                qtdTotalDoses: 0,
                qtdTotalValor: 0,
                id_vacina: vacina.id_vacina
            };

            state.estadoInicial.vacinas.push({
                ...vacina,
                ... { updatedAt: helpers.geraNumeroAleatorio() }
            })
        })

        if (state.chooseOrder == null) {
            for (const stateKey in state.empresas) {
                state.empresas[stateKey].vacinas = state.estadoInicial.vacinas;
                state.empresas[stateKey].carrinho.vacinas = state.estadoInicial.carrinhoVacina
                state.empresas[stateKey].updatedAt = helpers.geraNumeroAleatorio()
            }
        }
    },
    changeUpdatedAt(state) {
        state.updatedAt = helpers.geraNumeroAleatorio();
    },
    setChooseCompany(state, data) {
        state.chooseCompany = data;
    },
    updateEmpresasSequencia(state, payload) {
        state.empresas = payload.empresas
    },
    handleVisibilidadeObservacaoModal(state, empresa = null) {
        state.observacoesDasEmpresas.showModal = empresa != null;
        state.observacoesDasEmpresas.empresaSelecionada = empresa;
    },
    setVacinas(state, data = []) {
        state.vacinas = data.map((vacina) => {
            vacina.estimativas = state.chooseCompany == null ? vacina.estimativas : handleEstimativasEmpresa(vacina.estimativas);
            return {
                ...vacina,
                ... { updatedAt: helpers.geraNumeroAleatorio() }
            }
        });
    },
    setTermo(state, data = []) {
        state.termo = data[0];
    },
    setEmpresas(state, data = []) {
        let sequencia = 0;
        state.empresas = []
        state.empresas = data.map((empresa) => {
            return formataEstruturaEmpresa(empresa, state.carrinho, sequencia);
        })
    },
    updateEmpresaPorIdEmpresa(state, empresa = {}) {
        let index = helpers.findIndexInArray(state.empresas, empresa, 'id_empresa');
        state.empresas[index] = empresa;
    },
    addEmpresa(state, empresa = {}) {

        let empresaFormatada = formataEstruturaEmpresa(empresa, state.carrinho, state.empresas.length),
            vacinasPrecificadas = state.estadoInicial.vacinas.map((vacina) => {
                return {
                    ...vacina,
                    ... { possui_precificacao: vacina.id_tipo_preco === empresa.id_tipo_preco_empresa },
                    ... { id_empresa: empresa.id_empresa },
                    ... { doses: zerarDoses(vacina.doses) },
                    ... { updatedAt: helpers.geraNumeroAleatorio() }
                };
            })

        empresaFormatada.vacinas = vacinasPrecificadas.filter((vacina) => vacina.possui_precificacao);

        if (empresa.precificacoes != undefined) {
            let tipoIncentivo = ['com_fomento', 'sem_fomento',],
                tipoCategorias = ['dependente', 'terceiro', 'trabalhador',];

            empresa.precificacoes.forEach(precificacao => {
                let doses = precificacao.doses,
                    indexVacina = helpers.findIndexInArray(empresaFormatada.vacinas, precificacao, 'id_vacina');

                tipoIncentivo.forEach(incentivo => {
                    tipoCategorias.forEach(categoria => {

                        let item = empresaFormatada.vacinas[indexVacina].doses[incentivo][categoria],
                            novoItem = doses[incentivo][categoria];

                        if(novoItem != undefined)
                        {
                            empresaFormatada.vacinas[indexVacina].doses[incentivo][categoria] = {
                                ...item,
                                ... {
                                    valor: novoItem.valor,
                                    qtdDisponivel: novoItem.qtdDisponivel ? novoItem.qtdDisponivel : 0,
                                }
                            }
                        }
                    });
                });

                empresaFormatada.vacinas[indexVacina].updated_at = helpers.geraNumeroAleatorio();
            });
        }

        empresaFormatada.carrinho.vacinas = state.estadoInicial.carrinhoVacina
        empresaFormatada.updatedAt = helpers.geraNumeroAleatorio()

        state.empresas.push(empresaFormatada);
        state.observacoesDasEmpresas.observacoes[empresa.id_empresa] = '';
    },
    updateEmpresaDosesValores(state, payload) {
        let empresaUpdate = payload.empresa,
            campo = payload.campo,
            tipo = payload.tipo,
            inputValue = payload.value,
            vacina = payload.vacina,
            id_pedido_vacina = payload.id_pedido_vacina,
            valor_dose = payload.valor,
            indexEmpresa = helpers.findIndexInArray(state.empresas, empresaUpdate, 'id_empresa'),
            indexVacina = helpers.findIndexInArray(empresaUpdate.vacinas, vacina, 'id_vacina'),
            arrayVacinas = adicionaAtributoPossuiPrecificacao(state.empresas[indexEmpresa].vacinas, empresaUpdate),
            ehPrecificacaoUnica = arrayVacinas.filter((vacina) => vacina.possui_precificacao).length == 1;

        arrayVacinas.forEach((stateVacina, indexAtual) => {
            if (empresaUpdate["id_tipo_preco_empresa"] == stateVacina["id_tipo_preco"]) {
                let ehPrecificacaoMultiplaeAtual = stateVacina.id_vacina == vacina.id_vacina,
                    doseTipoValor = stateVacina["doses"][campo][tipo].valor,

                    tipoFinal = {
                        ...stateVacina["doses"][campo][tipo],
                        ... {
                            qtd: inputValue,
                            total: (doseTipoValor * inputValue),
                            id_pedido_vacina: id_pedido_vacina,
                        }
                    };

                if (ehPrecificacaoUnica || ehPrecificacaoMultiplaeAtual) {
                    stateVacina["doses"][campo][tipo] = tipoFinal
                }
            }
        })

    },
    recalcularCarrinhoEmpresa(state, payload) {
        let index = helpers.findIndexInArray(state.empresas, payload.empresa, 'id_empresa'),
            vacinas = state.empresas[index].vacinas,
            carrinhoDoses = 0,
            carrinhoValor = 0,
            carrinho = {
                vacinas: [],
                doses: 0,
                valor: 0
            },
            desabilitarEnvio = false,
            empresa = state.empresas[index];

        vacinas.forEach((vacina) => {
            let qtdTotalDoses = somaVacinaDoseCampo(vacina, 'qtd'),
                qtdTotalValor = parseFloat(somaVacinaDoseCampo(vacina, 'total').toFixed(2));

            carrinhoDoses += qtdTotalDoses
            carrinhoValor += qtdTotalValor

            if (vacina.id_tipo_preco == empresa.id_tipo_preco_empresa) {
                carrinho.vacinas[vacina.id_vacina] = { qtdTotalDoses, qtdTotalValor, id_vacina: vacina.id_vacina };
            }
        })

        carrinho.doses = carrinhoDoses;
        carrinho.valor = carrinhoValor.toFixed(2);

        state.empresas[index].carrinho = carrinho
        state.desabilitarEnvio = desabilitarEnvio
    },
    deleteEmpresa(state, payload) {
        let index = helpers.findIndexInArray(state.empresas, payload, 'id_empresa');
        if (index > -1) {
            state.empresas.splice(index, 1);
        }
    },
    limparCamposDosesVacinas(state, payload) {
        let indexEmpresa = helpers.findIndexInArray(state.empresas, payload.empresa, 'id_empresa'),
            empresa = state.empresas[indexEmpresa],
            vacina = payload.vacina,
            vacinasEmpresa = [];

        vacinasEmpresa = empresa.vacinas.map((vacinaEmpresa) => {
            if (vacina.id_vacina == vacinaEmpresa.id_vacina && vacinaEmpresa.id_tipo_preco == empresa.id_tipo_preco_empresa) {
                return {
                    ...vacinaEmpresa,
                    ... {
                        doses: zerarDoses(vacinaEmpresa.doses),
                        updatedAt: helpers.geraNumeroAleatorio(),
                    }
                }
            }
            return vacinaEmpresa;
        });

        state.empresas[indexEmpresa].vacinas = vacinasEmpresa;
        state.empresas[indexEmpresa].updatedAt = helpers.geraNumeroAleatorio();
    },
    padronizarSequenciaEmpresas(state) {
        let sequencia = 1;
        for (const stateKey in state.empresas) {
            state.empresas[stateKey].sequencia = sequencia
            state.empresas[stateKey].updatedAt = helpers.geraNumeroAleatorio();
            sequencia++;
        }
        state.updatedAt = helpers.geraNumeroAleatorio();
    },
    setChooseCampaign(state, data) {
        state.chooseCampaign = data
    },
    setChooseUnit(state, data) {
        state.chooseUnit = data
    },
    setResumo(state, desabilitarBotoesEnvio = false) {
        let resumoVacinas = getResumoVacinasFormatada(state.vacinas, state.chooseCompany != null),
            resumo = getResumoPorVacinaEmpresa(state.empresas, resumoVacinas, desabilitarBotoesEnvio);

        state.resumo = resumo
        state.desabilitarEnvio = state.chooseOrder != null ? false : resumo.desabilitarEnvio
        state.updatedAt = helpers.geraNumeroAleatorio()
    },
    updateEmpresaObservacao(state, payload) {
        let empresa = payload.empresa,
            observacao = payload.observacao,
            indexEmpresa = helpers.findIndexInArray(state.empresas, empresa, 'id_empresa');

        state.empresas[indexEmpresa].observacao = observacao
        state.empresas[indexEmpresa].updatedAt = helpers.geraNumeroAleatorio()
        state.observacoesDasEmpresas.observacoes[empresa.id_empresa] = observacao;
    },
    setInformacoesPersistencia(state, payload) {
        state.persistencia = {
            ...payload,
            ... { updatedAt: helpers.geraNumeroAleatorio() }
        }
    },
    setChooseOrder(state, payload) {
        state.chooseOrder = payload
    },
    setChooseObservacao(state, payload) {
        state.observacoesDasEmpresas.observacoes[payload.id_empresa] = payload.observacao != null ? payload.observacao : '';
    },
    setValidacaoDosesEmpresa(state, payload) {
        if (payload != null) {
            let comparacaoCamposVacinaIncentivo = (x, y) => {
                return x > y ? 1 : x < y ? -1 : 0;
            },
                doses = payload.doses.filter((doses) => {
                    return doses.valor_total > 0;
                });

            payload.doses = doses.sort(function (a, b) {
                return comparacaoCamposVacinaIncentivo(
                    [
                        comparacaoCamposVacinaIncentivo(a.id_vacina, b.id_vacina), -comparacaoCamposVacinaIncentivo(a.id_incentivo, b.id_incentivo),
                    ], [
                    comparacaoCamposVacinaIncentivo(b.id_vacina, a.id_vacina), -comparacaoCamposVacinaIncentivo(b.id_incentivo, a.id_incentivo),
                ]
                );
            });
        }
        state.validacaoDosesEmpresa = payload
    },
}